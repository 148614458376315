<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="secondary" space="40" v-if="address!=''">
      <v-container>
        <v-row justify="center" v-if="assetInfo">
          <v-col cols="12" class="col-md-4">
            <base-title :title="$ml.get('all_tokens')" class="text-center green--text" />
            <base-body space="0" class="text-center">
              <span class="white--text text-h5">
                {{ assetInfo.quantity / 10 ** assetInfo.decimals }}</span
              >
            </base-body>
          </v-col>
          <v-col cols="12" class="col-md-4">
            <base-title :title="$ml.get('in_circulation')" class="text-center primary--text"/>
            <base-body space="0" class="text-center">
              <span class="white--text text-h5"> {{  ((assetInfo.quantity )/ 10**8- amountInOrigin).toFixed(8) }}</span><br/>
              <span class="gray--text text-h6"> {{  ((assetInfo.quantity - amountInOrigin * 10**8)/assetInfo.quantity *100).toFixed(4) }}% </span>
            </base-body>
          </v-col>
          <v-col cols="12" class="col-md-4">
            <base-title
              :title="$ml.get('on_your_wallet')"
              class="text-center green--text"
            />
            <base-body space="0" class="text-center">
              <span class="white--text text-h5"> {{ amount }}</span><br/>
              <span class="gray--text text-h6"> {{ (amount * 10**8/(assetInfo.quantity - amountInOrigin * 10**8)*100).toFixed(4) }}%</span>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
import axios from "axios";
import { ebus } from "../../main.js";

export default {
  name: "PersonalStats",
  components: {},
  data: () => ({
    assetInfo: null,
    address: "",
    amount: 0,
    amountInOrigin: 0,
  }),
  created() {
    ebus.$on("userauthorized", (addr) => {
      this.address = addr;
      this.getAssetAmountInAddress(addr);
    });
  },
  mounted() {
    this.getAssetInfo();
    this.getAmountInOriginAcc();
  },
  methods: {
    getAssetInfo() {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/details/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("prices in usdt:", response.data);
          this.assetInfo = response.data;
        });
    },
    getAssetAmountInAddress(addr) {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/balance/" +
            addr +
            "/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("asset amount in addr:", response.data.balance / 10 ** 8);
          this.amount = response.data.balance / 10 ** 8;
        });
    },
    //получаем количество монет в обороте(вычитаем из общего количества количество монет на кошельке-эмитенте)
    getAmountInOriginAcc() {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/balance/3PA43tzsGHwR3gL1gqxCAFaUydwBgVhKJ7M/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log(
            "amount in origin addr:",
            response.data.balance
          );
          this.amountInOrigin = response.data.balance / 10 ** 8;
        });
    },
  },
  beforeDestroy() {
    ebus.$off("userauthorized");
  },
};
</script>
